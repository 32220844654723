import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import LogoBlack from '@/assets/img/icons/Logo-Firma.svg';
import BasicMenu from '../Button/BasicMenu';
import { ShopMenu, MenuItems } from './MenuRoutes';
import CloseIcon from '@mui/icons-material/Close';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 0,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    color: `white`,
    background: `red`,
  },
}));

const Cart = () => {
  const quantity = useSelector((state) => state.cart.quantity);
  return (
    <StyledBadge badgeContent={quantity} key="cart-style-badge">
      <FontAwesomeIcon icon={faShoppingCart} className='text-2xl'/>
    </StyledBadge>
  );
};

const SubMenus = [ShopMenu];

const NavBar = () => {
  const [clicked, setClicked] = useState(false);
  const [fixed, setFixed] = useState(false);
  const [subMenu, setSubMenu] = useState(false);
  const [subSubMenu, setSubSubMenu] = useState([]);
  const [subMenuCategory, setSubMenuCategory] = useState(0);
  const [imageSrc, setImageSrc] = useState(null);

  const handleSubMenu = (id, active) => {
    if (id < SubMenus.length) {
      setSubMenuCategory(id);
      setSubMenu(active);
    } else {
      closeSubMenu();
    }
  };

  const closeSubMenu = () => {
    setSubMenu(false);
  };

  const hoverChangeImage = (id) => {
    setImageSrc(id);
  };

  const toggleMenu = () => {
    setClicked(prevState => !prevState);
  };

  const handleScroll = () => {
    setFixed(window.scrollY !== 0);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <React.Fragment>
      <header className={`${fixed ? 'fixed' : 'relative'} flex flex-col z-20 w-full pb-5`}>
        <nav className="flex flex-row justify-between w-full z-30 bg-white py-5">
          <div className="w-full flex-grow flex justify-center items-center">
            <div className={`primary-nav ${clicked ? 'active' : ''} w-1/3 flex justify-around items-center`}>
              <ul className="flex flex-col md:flex-row gap-12 p-2 h-full justify-between mx-10 ">
                {MenuItems.slice(0, 3).map((item) => (
                  <li key={item.title} className='flex items-center text-center text-2xl'>
                    <NavLink
                      to={item.path}
                      className={item.cName}
                      onMouseOver={() => item.title === 'Shop' ? handleSubMenu(item.subMenu, true) : null}
                      onClick={closeSubMenu}
                    >
                      {item.title}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
            <div className="flex justify-center w-1/3">
              <NavLink to="/">
                <img src={LogoBlack} alt="Brand Logo" width="140" />
              </NavLink>
            </div>
            <div className={`primary-nav ${clicked ? 'active' : ''} w-1/3`}>
              <ul className="flex flex-col  md:flex-row gap-12 p-2 h-full justify-between grow mx-10">
                {MenuItems.slice(4, 6).map((item) => (
                  <li key={item.title} className='flex items-center text-center text-2xl'>
                    <NavLink
                      to={item.path}
                      className={item.cName}
                      onMouseOver={() => item.title === 'Shop' ? handleSubMenu(item.subMenu, true) : null}
                      onClick={closeSubMenu}
                    >
                      {item.title}
                    </NavLink>
                  </li>
                ))}
                <li>
                  <NavLink to="/Cart">
                    <Cart  className="text-2xl"/>
                  </NavLink>
                </li>
                <li className="flex gap-5 mx-2 cursor-pointertext-2xl">
                  <BasicMenu />
                </li>
              </ul>
            </div>
          </div>
        </nav>

        {/* Submenu */}
        <nav className={`${subMenu ? 'flex' : 'hidden'} flex-wrap justify-between w-full z-30 bg-white`} onMouseLeave={closeSubMenu}>
        <div className="submenu flex w-1/2 md:w-2/3">
          <ul className="flex flex-col gap-5">
            {SubMenus[subMenuCategory].map((item) => (
              <li key={item.info.title} className='text-2xl'>
                <NavLink
                  to={item.path}
                  onMouseOver={() => {
                    if (item?.submenus) {
                      setSubSubMenu(item.submenus);
                    } else {
                      setSubSubMenu(null);
                    }
                    hoverChangeImage(item.src || '/default-image.jpg');
                  }}
                  onClick={closeSubMenu}
                  className="cursor-pointer px-5 hover:underline-offset-1 hover:underline text-2xl"
                >
                  {item.info.title}
                </NavLink>
              </li>
            ))}
          </ul>

          <ul className='flex flex-col gap-5 bg-gray-100'>
            {subSubMenu && subSubMenu.map((item) => {
              return (
                <li key={item.info.title}>
                  <NavLink
                    to={item.path}
                    onClick={closeSubMenu}
                    onMouseOver={() => {
                      hoverChangeImage(item.src || '/default-image.jpg');
                    }}
                    className="cursor-pointer px-5 hover:underline-offset-1 hover:underline text-lg"
                  >
                    {item.info.title}
                  </NavLink>
                </li>
              );
            })}
          </ul>
          <div className='abosulute px-20 top-10 right-0'>
            <CloseIcon onClick={closeSubMenu} className='cursor-pointer'/>
          </div>
        </div>
          <div className="submenu bg-blue-200 w-1/3 overflow-hidden">
            <img
              className="w-full h-full object-cover object-top"
              src={imageSrc || '/default-image.jpg'}
              alt="Submenu Preview"
            />
          </div>
        </nav>
      </header>
    </React.Fragment>
  );
};

export default NavBar;