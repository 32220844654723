import { publicRequest,userRequestByParams } from './requestMethods';


export const getAllProductList = async () => {
  try {
    const response = await publicRequest.get('/products');
    return response.data;
  } catch (error) {
    console.error('Error fetching companies:', error);
    throw error;
  }
};


async function getProductList(cat) {
  return await publicRequest.get(cat ? `/products?category=${cat}` : '/products');
}

async function getProduct(id) {
  return await publicRequest.get(`/products/${id}`);
}

async function getProductGallery(folder) {
  const params = {
    folder:folder
  }
  return await userRequestByParams(params).get(`/media/files-url-by-folder`);
}

async function getProductByCategory(category) {
  return await publicRequest.get(`products/by-category?categories=${category}`);
}

async function getAllCategories(category) {
  return await publicRequest.get(`products/categories`);
}


async function getProductByCollection(collection) {
  const params = {
    collection:collection
  }
  return await userRequestByParams(params).get(`/products/by-collection`);
}

async function getProductByProductName(productName) {
  const params = {
    productName:productName
  }
  return await userRequestByParams(params).get(`/products/by-name`);
}

async function getColors(cat) {
  return await publicRequest.get(cat ? `products/colors?categories=${cat}` : '/products',
  );
}

async function getSizes(categories) {
  try {
    if (!categories || categories.length === 0) {
      console.warn("getSizes: No categories provided");
      return [];
    }

    // ✅ Support multiple categories (Convert array to query string)
    const categoryQuery = Array.isArray(categories)
      ? categories.map(encodeURIComponent).join(",")
      : encodeURIComponent(categories);

    const response = await publicRequest.get(`/products/sizes?categories=${categoryQuery}`);

    return response.data.availableSizes || [];
  } catch (error) {
    console.error("Error fetching sizes:", error);
    return [];
  }
}

export { getProductList, getProduct,getProductByCategory, getAllCategories, getColors, getSizes, getProductGallery, getProductByCollection,getProductByProductName };