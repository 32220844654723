import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import useImageValidation from '../../utils/ImageValidator';

const ImageBoxSm = ({ imgUrl, name, onClick }) => {
    const { imgUrlIsValid, isLoading } = useImageValidation(imgUrl);

    if (!imgUrlIsValid) {
        return null;
    }

    const handleImageClick = () => {
        onClick(imgUrl);
    };

    const handleMouseEnter = () => {
        onClick(imgUrl);
    };

    return (
        <Box
            className="flex justify-center items-center cursor-pointer border-[1px] !border-gray-300"
            sx={{
                width: 'fit-content',
                height: 120,
                bgcolor: '#FFFFFF',
                transition: 'background-color 0.3s ease', // Smooth transition for background-color change
                '&:hover': {
                    bgcolor: '#E0E0E0',
                },
            }}
            onClick={handleImageClick}
            onMouseEnter={handleMouseEnter}
        >
            {isLoading ? (
                <Skeleton variant="rectangular" width={70} height={70} animation="wave" />
            ) : (
                <img className="w-auto h-full p-1 object-contain" src={imgUrl} alt={name} />
            )}
        </Box>
    );
};

export default ImageBoxSm;