import React from 'react';
import MagazinesLogos from '../Images/LogosMagazine';
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y, Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './MagazineSliderStyle.css'

export default function MagazinesSlider() {
  return (
    <div className="magazine-slider-container">
      <Swiper
        slidesPerView={3} /* Default */
        spaceBetween={20}
        navigation={true}
        pagination={{ clickable: true, dynamicBullets: true }}
        modules={[A11y, Navigation, Pagination]}
        className="mySwiper"
        breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 0,
              centeredSlides:true,
            },
            480: {
              slidesPerView: 1,
              spaceBetween: 10,
              centeredSlides:true,
            },
            660: {
              slidesPerView: 1,
              spaceBetween: 0,
              centeredSlides:true,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 0,
            },
            960: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            1280: {
              slidesPerView: 4,
              spaceBetween: 40,
            },
            1440: {
              slidesPerView: 4,
              spaceBetween: 50,
            },
          }}
      >
        {MagazinesLogos.map((item, index) => (
          <SwiperSlide
          key={index}
          className="flex justify-center items-center !h-auto max-h-[600px]"
        >
          <a href={item.url} target="_blank" rel="noopener noreferrer">
            <img
              src={item.src}
              alt={item.title || "Magazine Logo"}
              className="magazine-logo max-w-full !max-h-fit object-contain"
            />
          </a>
        </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
