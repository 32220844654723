import React, { useState } from 'react';
import { Box, IconButton, Card, CardMedia } from '@mui/material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import DeleteIcon from '@mui/icons-material/Delete';
import { isValidImageUrl } from '../../../../utils/ImageValidator';

const MAX_IMAGES = 6;

const MultipleImageUpload = ({ onImagesChange, onUpload }) => {
  const [previewMultipleImages, setPreviewMultipleImages] = useState([]);

  const handleImageSelection = async (event) => {
    if (previewMultipleImages.length >= MAX_IMAGES) {
      alert('You can only select up to 6 images.');
      return;
    }

    const file = event.target.files[0];
    if (!file) return;

    const localPreviewUrl = URL.createObjectURL(file);
    const isValid = await isValidImageUrl(localPreviewUrl);
    if (isValid) {
        setPreviewMultipleImages([...previewMultipleImages, { file, previewUrl: localPreviewUrl }]);
    } else {
      alert('Invalid image file. Please select a valid image.');
    }
  };

  const handleRemoveImage = (index) => {
    const updatedPreviewImages = previewMultipleImages.filter((_, i) => i !== index);
    setPreviewMultipleImages(updatedPreviewImages);
  };

  const handleUpload = async () => {
    const uploadedImages = await onUpload(previewMultipleImages.map(img => img.file));
    onImagesChange(uploadedImages);
  };

  return (
    <Box className='flex flex-wrap gap-4'>
      {previewMultipleImages.map((img, index) => (
        <Card key={index} className='relative w-32 h-32 shadow-md'>
          <CardMedia component='img' image={img.previewUrl} className='w-full h-full object-cotain rounded-md p-5' />
          <IconButton 
            className='!absolute top-[0] right-0 m-1 p-1 rounded-full shadow-md !bg-red-600' 
            onClick={() => handleRemoveImage(index)}
            size='small'
          >
            <DeleteIcon className='text-white !text-[1rem]' />
          </IconButton>
        </Card>
      ))}

      {previewMultipleImages.length < MAX_IMAGES && (
        <Box className='w-32 h-32 flex items-center justify-center border-2 border-dashed cursor-pointer rounded-md'>
          <input type='file' accept='image/*' onChange={handleImageSelection} className='hidden' id='upload-image' />
          <label htmlFor='upload-image'>
            <IconButton component='span'>
              <AddPhotoAlternateIcon fontSize='large' />
            </IconButton>
          </label>
        </Box>
      )}

    </Box>
  );
};

export default MultipleImageUpload;
