import React from 'react';
import { Box, Typography, FormControl, InputLabel, Select, MenuItem, InputAdornment } from '@mui/material';
import WcIcon from '@mui/icons-material/Wc';
import FemaleIcon from '@mui/icons-material/Female';
import MaleIcon from '@mui/icons-material/Male';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';

const GenderSelection = ({ newProduct, setNewProduct }) => {
  const handleGenderChange = (event) => {
    setNewProduct((prevState) => ({
      ...prevState,
      gender: event.target.value,
    }));
  };

  return (
    <Box className="mb-4">
      <Typography variant="h6">Género</Typography>
      <FormControl variant="standard" className="w-auto">
        <InputLabel>Seleccionar Género</InputLabel>
        <Select
          value={newProduct.gender || ""}
          onChange={handleGenderChange}
          startAdornment={
            <InputAdornment position="start">
              <WcIcon />
            </InputAdornment>
          }
        >
          <MenuItem value="Women">
            Mujer
          </MenuItem>
          <MenuItem value="Men">
            Hombre
          </MenuItem>
          <MenuItem value="Unisex">
            Unisex
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default GenderSelection;