export const handleWhatsAppClick = () => {
    // Número de teléfono con el que se quiere contactar (incluye el código de país sin el signo +)
    const phoneNumber = process.env.REACT_APP_WHATSAPP_NUMBER;
    const whatsappUrl = `https://wa.me/${phoneNumber}`;

    // Abrir el enlace de WhatsApp
    window.open(whatsappUrl, '_blank');
};

export const handleWhatsAppRedirect = (product) => {
    const phoneNumber = "+123456789"; // Replace with your number
    const message = `Hola, estoy interesado en el producto: ${product?.title}`;
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, "_blank");
};