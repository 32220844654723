import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import ProductsFilter from '@/components/Filters/ProductFilter';

const Container = styled.section``;
const MediaContainer = styled.div`
  height: auto;
`;

const ShoppingList = () => {
  const videoEl = useRef(null);
  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch(error => {
        // console.error('Error attempting to play', error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);

  return (
    <>
      <Container>
        <ProductsFilter />
      </Container>
    </>
  );
};

export default ShoppingList;
