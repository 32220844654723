import 'swiper/css/bundle';

import React from 'react';
import styled from 'styled-components';

import AnimateLogo from '@/components/AnimateLogo.jsx';
import { LogoP1 } from '@/components/AnimationSVG/LogoP1';
import GridSlider from '@/components/Carousel/GridSlider';
import HistorySlider from '@/components/Carousel/HistorySlider';
import NajjatSlider from '@/components/Carousel/NajjatSlider';
import NavBar from '@/components/NavBar/NavBar';
import Newsletter from '@/components/Newsletter';
import VerticalCarousel from '@/components/VerticalCarousel';
import { CollectionWinter } from '@/Data/data';
import { Magazines } from '@/Data/magazines';
import { LogoP1P1 } from '../components/AnimationSVG/LogoP1-part1';
import { LogoP1P2 } from '../components/AnimationSVG/LogoP1-part2';
import { Box } from '@mui/material';
import LogoBlack from '@/assets/img/icons/Logo-Firma.svg';
import VideoSlider from '../components/Carousel/VideoSlider';

const Container = styled.section`
  position: relative;
`;

const ImageContainer = styled.div``;

const Image = styled.img`
  object-fit: cover;
  object-position: center;
  height: 100%;
  width: 100%;
`;
const FirmContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  left: 25%;
  height: 70vh;
  width: 60vw;
  @media (max-width: 600px) {
    left: 50%;
    transform: translateX(-50%);
    height: 50%;
  }
`;

const SubTitle = styled.h3`
  text-align: left;
  font-size: 1.2rem;
`;

const AuthorContainer = styled.div`
  & h2 {
    font-size: 3rem;
  }

  & h3 {
    font-size: 2rem;
    line-height: 1rem;
    padding-left: 10rem;
  }
`;

const Nosotros = () => (
  <React.Fragment>
    <Container className='m-auto max-w-[1440px]'>
    <div className="flex flex-col md:flex-row m-auto justify-center items-center relative h-[800px]">
      <div className="hidden lg:block absolute top-50 left-[5%] z-10 p-4  lg:w-[420px] xl:w-[600px]">
        <LogoP1P1 className="logo-1" />
      </div>
      <ImageContainer className='w-[400px] xl:w-[450px]'>
        <Image src={require('../assets/img/Artista/art-02.png')} className="image" />
      </ImageContainer>
      <div className="hidden lg:block absolute top-50 right-[15%] xl:right-[10%] z-10 p-4 lg:w-[420px] xl:w-[650px]">
        <LogoP1P2 className="logo-2" />
      </div>
      <div className="block lg:hidden  z-10 py-20 w-[200px]">
        <img src={LogoBlack} alt="Brand Logo" width="300" />
      </div>
    </div>
    </Container>


    
    <Container className='flex w-full justify-center items-center p-5 md:p-24 lg:p-36'>
      <h2 className=' text-3xl md:text-[55px] font-bold text-center md:leading-[3.5rem] lg:leading-[5rem]'>“DISEÑADORA QUE DESAFÍA LA LÓGICA Y ABRAZA LA LOCURA CREATIVA”</h2>
    </Container>


    <Container maxWidth='xl' className='flex flex-col lg:flex-row justify-center items-center py-10 lg:py-20  h-auto xl:h-[110vh]'>
      <Box className='w-full lg:w-1/2 h-full flex flex-col p-10'> 
      <h2 className='text-3xl md:text-[35px] lg:text-[45px] xl:text-[55px] text-left leading-[3.6rem] uppercase w-full md:w-10/12 grow'>
        01 Inspiración 
      </h2>
      <ImageContainer>
        <Image src={require('../assets/img/Summer2022/LaPerez/5L3A0279.jpg')} className="image" />
      </ImageContainer>
      </Box>
      <Box className='w-full lg:w-1/2 flex flex-col gap-5 px-10 lg:px-0'> 
        <h2 className='text-2xl  lg:text-[36px] xl:text-[48px] font-bold text-center md:text-left  leading-1 lg:leading-[2.5rem] xl:leading-[3.6rem] uppercase w-full xl:w-10/12'>“La ropa, un lenguaje universal para expresar tus emociones, tu vulnerabilidad y crear conexión con tu interior.”</h2>
        <p className='text-lg md:text-[24px] font-bold text-center md:text-left w-full md:w-10/12 text-gray-600'>Najjat Harb busca plasmar en cada prenda un mensaje de superación personal 
          Usa sus experiencias de vida para crear diseños que ayuden a sanar el alma 
          Su objetivo es poder hacer diseños que te ayuden a conectar con tu interior </p>
      </Box>
    </Container>


    <Container className='bg-black py-10'>
    <h2 className='text-3xl md:text-[55px] text-left leading-1 md:leading-[3.6rem] uppercase w-10/12 grow  text-white '>
        02 Raices 
      </h2>
      <HistorySlider />
    </Container>
    <Box>
      <VideoSlider/>
    </Box>
    <Container className="magazine-slider overflow-none">
      <h2 className='text-3xl md:text-[55px] text-left leading-1 md:leading-[3.6rem] uppercase w-10/12 grow mr-5'>03 Press</h2>
      <GridSlider Magazines={Magazines}></GridSlider>
    </Container>
    <Newsletter></Newsletter>
  </React.Fragment>
);

export default Nosotros;
