import React, { useState, useEffect } from 'react';
import {
  Autocomplete,
  Card,
  CardMedia,
  Box,
  Typography,
  Button,
  IconButton,
  InputAdornment,
  Dialog,
  DialogContent,
  DialogTitle,
  CircularProgress,
  TextField,
  FormControlLabel,
  Checkbox,
  Chip,
  Paper
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ImageIcon from '@mui/icons-material/Image';
import CheckroomIcon from '@mui/icons-material/Checkroom';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import TitleIcon from '@mui/icons-material/Title';
import CollectionsIcon from '@mui/icons-material/Collections';
import { uploadImage } from '../../../../services/gcpServices';
import MultipleImageUpload from './MultipleImageUpload';
import SizeSelectionBox from './SizeSelectionBox';
import { getAllCategories } from '../../../../services/products';
import CategorySelector from './CategorySelector';
import ColorSelector from './ColorSelector';
import CollectionSelector from './CollectionSelector';
import GenderSelection from './GenderSelection';

const sizeChartData = [
  { size: "XS" },
  { size: "S" },
  { size: "M" },
  { size: "L" },
  { size: "XL" },
  { size: "XXL" },
  { size: "XXXL" },
];

const AddProductModal = ({ open, onClose, onSave }) => {
  const [newProduct, setNewProduct] = useState({
    productTitle: '',
    description: '',
    price: '',
    imgUrl: '',
    categories: [],
    sizes: sizeChartData.reduce((acc, { size }) => ({ ...acc, [size]: 0 }), {}),
  });
  const [imageFile, setImageFile] = useState(null);
  const [allCategories, setAllCategories] = useState([]);
  const [loading, setLoading] = useState(false); // Loading state
  const [message, setMessage] = useState(null); // Success or error message

  useEffect(() => {
    const loadCategories = async () => {
      try {
        const categories = await getAllCategories();
        setAllCategories(categories.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };
    loadCategories();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewProduct((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImageFile(e.target.files[0]);
      setNewProduct((prevState) => ({
        ...prevState,
        imgUrl: URL.createObjectURL(e.target.files[0]),
      }));
    }
  };

  const handleRemoveImage = () => {
    setImageFile(null);
    setNewProduct((prevState) => ({ ...prevState, imgUrl: '' }));
  };

  const handleSizeChange = (size, value) => {
    setNewProduct((prevState) => ({
      ...prevState,
      sizes: { ...prevState.sizes, [size]: value },
    }));
  };

  const handleSave = async () => {
    setLoading(true); // Show loading dialog
    setMessage("Guardando el producto, por favor espere...");

    try {
      let imgUrl = newProduct.imgUrl;
      if (imageFile) {
        const folder = `${newProduct.gender || "unisex"}/${newProduct.categories.join("/") || "general"}`;
        imgUrl = await uploadImage(imageFile, folder);
      }

      await onSave({ ...newProduct, imgUrl });
      setMessage("✅ Producto guardado exitosamente.");
      setTimeout(() => {
        setMessage(null);
        setLoading(false);
        onClose();
      }, 2000);
    } catch (error) {
      console.error('❌ Error al guardar el producto:', error);
      setMessage("❌ Error al guardar el producto. Inténtelo de nuevo.");
      setTimeout(() => setMessage(null), 3000);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle className="flex justify-between items-center">
        Agregar Producto
        <IconButton onClick={onClose} disabled={loading}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Card className="p-4 shadow-lg rounded-xl">
          {newProduct.imgUrl ? (
            <Box position="relative" className="flex justify-center">
              <CardMedia
                component="img"
                image={newProduct.imgUrl}
                title={newProduct.productTitle}
                sx={{
                    width: 'fit-content',
                    height: 'auto',
                    maxHeight: 400,
                    display: 'block',
                    margin: 'auto',
                    objectFit: 'contain',
                  }}
              />
              <IconButton
                onClick={handleRemoveImage}
                className="absolute top-2 right-2 bg-white shadow-lg h-10 w-10"
                disabled={loading}
              >
                <DeleteIcon color="error" />
              </IconButton>
            </Box>
          ) : (
            <Box className="flex flex-col items-center py-4">
              <input
                accept="image/*"
                type="file"
                onChange={handleImageChange}
                className="hidden"
                id="upload-image"
              />
              <label htmlFor="upload-image">
                <Button component="span" variant="contained" startIcon={<ImageIcon />} disabled={loading}>
                  Subir Imagen
                </Button>
              </label>
            </Box>
          )}
        </Card>

        <Box className='mt-4 flex gap-2'>
          <TextField
            label="Título del Producto"
            name="productTitle"
            value={newProduct.productTitle}
            onChange={handleInputChange}
            variant="standard"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <TitleIcon />
                </InputAdornment>
              ),
            }}
            disabled={loading}
          />
          <TextField
            label="Precio"
            variant='standard'
            name="price"
            type="number"
            value={newProduct.price}
            onChange={handleInputChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AttachMoneyIcon />
                </InputAdornment>
              ),
            }}
            disabled={loading}
          />
        </Box>

        <Box className="mt-4 flex flex-col gap-4">
          <GenderSelection newProduct={newProduct} setNewProduct={setNewProduct} disabled={loading}/>
          <CollectionSelector newProduct={newProduct} setNewProduct={setNewProduct} disabled={loading}/>
          <SizeSelectionBox newProduct={newProduct} sizeChartData={sizeChartData} handleSizeChange={handleSizeChange}/>
          <ColorSelector  newProduct={newProduct} setNewProduct={setNewProduct} disabled={loading}/>
          <CategorySelector allCategories={allCategories} newProduct={newProduct} setNewProduct={setNewProduct} disabled={loading}/>
        </Box>

        <Box className='my-5'>
        <Typography variant="h6" className="flex items-center gap-2"><CollectionsIcon/> Galería de Imágenes</Typography>
          <MultipleImageUpload disabled={loading} />
        </Box>

        <Box className="flex justify-between mt-6">
          <Button onClick={onClose} variant="contained" color="error" disabled={loading}>Cancelar</Button>
          <Button onClick={handleSave} variant="contained" color="primary" disabled={loading}>Guardar</Button>
        </Box>
      </DialogContent>

      {/* ✅ Loading Dialog */}
      <Dialog open={loading}>
        <DialogContent className="flex flex-col items-center gap-4">
          <CircularProgress />
          <Typography>{message}</Typography>
        </DialogContent>
      </Dialog>
    </Dialog>
  );
};

export default AddProductModal;