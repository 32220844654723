import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { logout } from '@/services/user';

const Container = styled.div`
  & #basic-button {
    min-width: 5px;
    color: black;
  }
`;

export default function BasicMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const dispatch = useDispatch();
  const user = useSelector(state => state.user.currentUser);

  const handleLogout = e => {
    console.log(e);
    logout(dispatch);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Container>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <FontAwesomeIcon icon={faUser} className='text-2xl'/>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {user ? (
          <Link to="/login">
            <MenuItem onClick={handleLogout}>Cerrar Sesion</MenuItem>
          </Link>
        ) : (
          <div>
            <Link to="/register">
              <MenuItem onClick={handleClose}>Registrarse</MenuItem>
            </Link>
            <Link to="/login">
              <MenuItem onClick={handleClose}>Ingresar</MenuItem>
            </Link>
          </div>
        )}
      </Menu>
    </Container>
  );
}
