import {
  faListDots,
  faMinus,
  faPlus,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, Disclosure, Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import Products from "@/components/Products";
import { getColors, getSizes } from "@/services/products";

const sortOptions = [
  { name: "Popular", value: "mvp" },
  { name: "Novedades", value: "newest" },
  { name: "Precio: $ - $$$", value: "asc" },
  { name: "Precio: $$$ - $", value: "desc" },
];

const subCategories = [
  { name: "Vestido", href: "#" },
  { name: "Falda", href: "#" },
  { name: "Bolsas", href: "#" },
  { name: "Zapatos", href: "#" },
  { name: "Accesorios", href: "#" },
];

export default function ProductFilters() {
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [useFilters, setUseFilters] = useState({});
  const [sort, setSort] = useState("newest");
  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const location = useLocation();
  const title = location.pathname.split("/")[2];

  useEffect(() => {
    const fetchFilters = async () => {
      if (!title) return;
      setLoading(true);
      setError(null);

      try {
        const [colorsRes, sizesRes] = await Promise.all([
          getColors(title),
          getSizes(title),
        ]);

        console.log(colorsRes);
        console.log(sizesRes);

        setColors(colorsRes.data || []);
        setSizes(sizesRes.data || []);
      } catch (err) {
        console.error("Error fetching filter options:", err);
        setError("Error loading filters.");
      } finally {
        setLoading(false);
      }
    };

    fetchFilters();
  }, [title]);

  const filtersOptions = [
    { id: "color", name: "Color", options: colors },
    { id: "size", name: "Size", options: sizes },
  ];

  const handleFilters = (e) => {
    const { name, value } = e.target;
    setUseFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className="bg-white">
      <div>
        {/* Mobile filter dialog */}
        <Transition.Root show={mobileFiltersOpen} as={Fragment}>
          <Dialog as="div" className="relative z-40 lg:hidden" onClose={setMobileFiltersOpen}>
            <Transition.Child as={Fragment} enter="transition-opacity ease-linear duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="transition-opacity ease-linear duration-300" leaveFrom="opacity-100" leaveTo="opacity-0">
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child as={Fragment} enter="transition ease-in-out duration-300 transform" enterFrom="translate-x-full" enterTo="translate-x-0" leave="transition ease-in-out duration-300 transform" leaveFrom="translate-x-0" leaveTo="translate-x-full">
                <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                  <div className="flex items-center justify-between px-4">
                    <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                    <button type="button" className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400" onClick={() => setMobileFiltersOpen(false)}>
                      <span className="sr-only">Close menu</span>
                      <FontAwesomeIcon icon={faXmark} />
                    </button>
                  </div>

                  {/* Filters */}
                  <form className="mt-4 border-t border-gray-200">
                    <h3 className="sr-only">Categories</h3>
                    <ul className="px-2 py-3 font-medium text-gray-900">
                      {subCategories.map((category) => (
                        <li key={category.name}>
                          <a href={category.href} className="block px-2 py-3">
                            {category.name}
                          </a>
                        </li>
                      ))}
                    </ul>

                    {filtersOptions.map((section) => (
                      <Disclosure as="div" key={section.id} className="border-b border-gray-200 py-6">
                        {({ open }) => (
                          <>
                            <h3 className="-my-3 flow-root">
                              <Disclosure.Button className="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
                                <span className="font-medium text-gray-900">{section.name}</span>
                                <span className="ml-6 flex items-center">
                                  {open ? <FontAwesomeIcon icon={faMinus} className="h-5 w-5" /> : <FontAwesomeIcon icon={faPlus} className="h-5 w-5" />}
                                </span>
                              </Disclosure.Button>
                            </h3>
                            <Disclosure.Panel className="pt-6">
                              <div className="space-y-4">
                                {section.options.map((option, optionIdx) => (
                                  <div key={option} className="flex items-center">
                                    <input id={`filter-${section.id}-${optionIdx}`} name={`${section.id}`} defaultValue={option} type="radio" className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" onChange={handleFilters} />
                                    <label htmlFor={`filter-${section.id}-${optionIdx}`} className="ml-3 text-sm text-gray-600">{option}</label>
                                  </div>
                                ))}
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    ))}
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">

          <section aria-labelledby="products-heading" className="pt-6 pb-24">
            <div className="grid grid-cols-1 gap-y-10 lg:grid-cols-12">
              {/* Filters */}
              <form className="hidden lg:block lg:col-span-2">
                {filtersOptions.map((section) => (
                  <Disclosure as="div" key={section.id} className="border-b border-gray-200 py-6">
                    {({ open }) => (
                      <>
                        <h3 className="-my-3 flow-root">
                          <Disclosure.Button className="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
                            <span className="font-medium text-gray-900">{section.name}</span>
                          </Disclosure.Button>
                        </h3>
                      </>
                    )}
                  </Disclosure>
                ))}
              </form>

              {/* Product grid */}
              <div className="lg:col-span-10">
                <Products useFilters={useFilters} cat={title} sort={sort} />
              </div>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
}