const cName = 'logo-item';

const SocialLogos = [
  {
    id: 2,
    title: 'Instagram',
    src: require('../../assets/img/icons/social/instagram_icn.svg').default,
    url: 'https://www.instagram.com/najjatharb/',
    cName,
  },
  {
    id: 1,
    title: 'Facebook',
    src: require('../../assets/img/icons/social/facebook_icn.svg').default,
    url: 'https://www.facebook.com/najjatharb/',
    cName,
  },
  {
    id: 3,
    title: 'Linked In',
    src: require('../../assets/img/icons/social/linkedin-3.png'),
    url: 'https://www.linkedin.com/company/najjat-harb?trk=public_post_reshare_feed-actor-name',
    cName,
  },
  {
    id: 4,
    title: 'Tik Tok',
    src: require('../../assets/img/icons/social/tik-tok.png'),
    url: 'https://www.tiktok.com/@najjatharbrand?_t=ZS-8upRrkVnSkB&_r=1',
    cName,
  },
  {
    id: 4,
    title: 'Youtube',
    src: require('../../assets/img/icons/social/youtube.png'),
    url: 'http://www.youtube.com/@najjatharb1208',
    cName,
  },
];

export default SocialLogos;
