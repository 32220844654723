import { publicRequest } from './requestMethods';


/**
 * Uploads an image to the server.
 * @param {File} file - The image file to upload.
 * @param {string} folderName - The folder where the image should be stored.
 * @returns {Promise<string>} - The URL of the uploaded image.
 */
export async function uploadImage(file, folderName) {
  
  if (!(file instanceof File)) {
    throw new Error("Provided file is not a valid File object.");
  }

  const formData = new FormData();
  formData.append("file", file);
  formData.append("folder", folderName);

  try {
    const response = await publicRequest.post("/media/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (response.status !== 200 || !response.data.success) {
      throw new Error(response.data.message || "Failed to upload image");
    }

    console.log("Image uploaded successfully:", response.data.url);
    return response.data.url;
  } catch (error) {
    console.error("Error uploading image:", error.message);
    throw new Error("Image upload failed. Please try again.");
  }
}

export async function deleteFile(folderName, fileName) {
  try {
    const response = await publicRequest.delete('/cloud/delete-file', {
      data: { folder: folderName, fileName: fileName },
    });

    if (response.status !== 200) {
      throw new Error('Failed to delete file');
    }

    return response.data.message;
  } catch (error) {
    console.error('Error deleting file:', error);
    throw new Error(error.response?.data?.message || 'Failed to delete file');
  }
}

export async function listFiles() {
    const response = await publicRequest.get('/cloud/list-files');
    return response.data.files;
  }
