// src/components/SizeChartDialog.js

import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Typography,
  Drawer,
  Divider,
  Box,
} from "@mui/material";
import "./SizeChartDialogStyle.css"

const sizeChartData = [
  { size: "XS (2)", bust: '34" - 35"', waist: '25" - 26"', hips: '35" - 36"' },
  { size: "S (4)", bust: '35" - 36"', waist: '26" - 27"', hips: '36" - 37"' },
  { size: "M (6 - 8)", bust: '37" - 38"', waist: '28" - 29"', hips: '38" - 39"' },
  { size: "L (10 - 12)", bust: '39" - 40"', waist: '30" - 31"', hips: '40" - 41"' },
  { size: "XL (14 - 16)", bust: '42" - 43"', waist: '33" - 34"', hips: '43" - 44"' },
  { size: "XXL (18 - 20)", bust: '46" - 47"', waist: '37" - 38"', hips: '47" - 48"' },
  { size: "XXXL (22 - 24)", bust: '48" - 50"', waist: '39" - 41"', hips: '49" - 51"' },
];

const SizeChartDialog = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      {/* Button to open the dialog */}
      <Box className='w-full flex justify-end'>
        <Button variant="text" className="hover:bg-gray-100 focus:outline-none" onClick={() => setOpen(true)}>
            <Typography variant="body" className="underline text-gray-600 !text-[12px]"> Guia de Tallas</Typography>
        </Button>
      </Box>
   
      
      {/* Dialog with the size chart */}
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
        <Box className='w-full flex justify-center'>
        <DialogTitle className="!text-md !font-tenor_Sans">Guia de Tallas</DialogTitle>
        </Box>
        <Divider/>
        <Box className="w-full flex flex-col justify-center items-center">            
            <Box className="w-10/12 flex flex-col gap-5 text-center justify-center p-4">
            <Typography variant="body2" className="text-gray-700 !text-[12px]">
                Las tallas pueden variar dependiendo del contexto de fabricación y el estilo.
            </Typography>
            <Typography variant="body2" className="text-gray-700 mt-2 !text-[12px]">
                Aquí tienes nuestra guía de tallas para ayudarte a encontrar el ajuste sugerido más adecuado.
            </Typography>
            <Typography variant="body2" className="text-gray-700 mt-2 !text-[12px]">
                ¿No estás seguro de tu talla? Prueba nuestra herramienta de ajuste para descubrir el tamaño adecuado. 
                <span 
                className="text-blue-600 cursor-pointer underline"
                onClick={() => alert('Redirect to fit tool')}
                >
                Encuentra mi talla
                </span>
            </Typography>
            </Box>
        </Box>
        <DialogContent>
          <TableContainer>
          <Table className="size-chart-table" >
              <TableHead>
                <TableRow>
                  <TableCell><b>SIZE</b></TableCell>
                  <TableCell><b>BUST</b></TableCell>
                  <TableCell><b>WAIST</b></TableCell>
                  <TableCell><b>HIPS</b></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sizeChartData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell><Typography variant="body2" className="text-sm">{row.size} </Typography></TableCell>
                    <TableCell><Typography variant="body2" className="text-sm">{row.bust} </Typography></TableCell>
                    <TableCell><Typography variant="body2" className="text-sm">{row.waist}</Typography></TableCell>
                    <TableCell><Typography variant="body2" className="text-sm">{row.hips} </Typography></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SizeChartDialog;