import React, { useState } from 'react';
import {
  Box,
  Typography,
  Autocomplete,
  TextField,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@mui/material';
import { SketchPicker } from 'react-color';
import ColorLensIcon from '@mui/icons-material/ColorLens';

const defaultColors = [
  { name: "Rojo", color: "#FF0000" },
  { name: "Azul", color: "#0000FF" },
  { name: "Verde", color: "#008000" },
  { name: "Amarillo", color: "#FFFF00" },
  { name: "Negro", color: "#000000" },
  { name: "Blanco", color: "#FFFFFF" },
  { name: "Púrpura", color: "#800080" },
  { name: "Naranja", color: "#FFA500" },
  { name: "Gris", color: "#808080" },
  { name: "Marrón", color: "#A52A2A" }
];

const ColorSelector = ({ allColors = defaultColors, newProduct, setNewProduct }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [newColorName, setNewColorName] = useState('');
  const [selectedColor, setSelectedColor] = useState('#000000');

  const handleColorChange = (event, newValue) => {
    const lastValue = newValue[newValue.length - 1];
    if (typeof lastValue === "string" && !allColors.some(color => color.name === lastValue)) {
      setNewColorName(lastValue);
      setOpenDialog(true);
    } else {
      setNewProduct((prevState) => ({ ...prevState, colors: newValue }));
    }
  };

  const handleAddColor = () => {
    const trimmedColor = newColorName.trim();
    if (trimmedColor && !newProduct.colors.some((c) => c.name === trimmedColor)) {
      const newColor = { name: trimmedColor, color: selectedColor };
      setNewProduct((prevState) => ({
        ...prevState,
        colors: [...prevState.colors, newColor],
      }));
    }
    setNewColorName('');
    setOpenDialog(false);
  };

  const handleRemoveColor = (color) => {
    setNewProduct((prevState) => ({
      ...prevState,
      colors: prevState.colors.filter((col) => col.name !== color.name),
    }));
  };

  return (
    <Box className="mt-4">
      <Typography variant="h6" className="flex items-center gap-2"><ColorLensIcon /> Colores</Typography>
      <Autocomplete
        multiple
        freeSolo
        options={allColors.sort((a, b) => a.name.localeCompare(b.name))}
        groupBy={(option) => option.name[0].toUpperCase()}
        getOptionLabel={(option) => option.name}
        value={newProduct.colors}
        onChange={handleColorChange}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              key={index}
              label={
                <Box className="flex items-center gap-2">
                  <Box 
                    sx={{ width: 16, height: 16, borderRadius: "50%", border: "1px solid #000", backgroundColor: option.color }}
                  />
                  {option.name}
                </Box>
              }
              {...getTagProps({ index })}
              color="primary"
              variant="outlined"
              onDelete={() => handleRemoveColor(option)}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Seleccionar o agregar color"
            variant="standard"
          />
        )} 
        filterSelectedOptions
      />

      {/* Dialog for adding a new color */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Agregar Nuevo Color</DialogTitle>
        <DialogContent>
          <TextField
            label="Nombre del color"
            fullWidth
            variant="standard"
            value={newColorName}
            onChange={(e) => setNewColorName(e.target.value)}
          />
          <Box className="mt-2">
            <Typography variant="body2">Selecciona el color:</Typography>
            <SketchPicker
              color={selectedColor}
              onChangeComplete={(color) => setSelectedColor(color.hex)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="secondary">Cancelar</Button>
          <Button onClick={handleAddColor} color="primary" variant="contained">Agregar</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ColorSelector;
