import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import ImageBoxSm from './ImageBoxSm';
import Skeleton from '@mui/material/Skeleton';
import useImageValidation from '../../utils/ImageValidator';

const ProductGallery = ({ productImages, mainProductImage, productName }) => {
    const { imgUrlIsValid, isLoading: isMainImageLoading } = useImageValidation(mainProductImage);
    const [currentImage, setCurrentImage] = useState(mainProductImage);
    const [isImagesLoading, setImagesLoading] = useState(true);

    const handleCurrentImage = (imgUrl) => {
        setCurrentImage(imgUrl);
    };

    // Simulating loading for productImages
    useEffect(() => {
        console.log(mainProductImage);
        if (productImages.length > 0) {
            // Simulate image loading delay
            const timer = setTimeout(() => {
                setImagesLoading(false);
            }, 1000); // Adjust this time to simulate the loading duration

            return () => clearTimeout(timer); // Cleanup timer on unmount
        } else {
            setImagesLoading(false);
        }
    }, [productImages]);

    return (
        <Box className="flex flex-col-reverse md:flex-row justify-center items-center gap-2 md:gap-10 ">
            {/* Thumbnail Gallery */}
            <div className="slider flex flex-row md:flex-col gap-5">
                {isImagesLoading ? (
                    // Simulating Skeleton loading effect for thumbnails
                    Array.from({ length: 3 }, (_, index) => (
                        <Skeleton 
                            key={index} 
                            variant="rectangular" 
                            width={100} 
                            height={100} 
                            animation="wave" 
                        />
                    ))
                ) : (
                    productImages.map((item, index) => (
                        <ImageBoxSm 
                            key={index} 
                            imgUrl={item.url} 
                            name={`Image ${index + 1}`} 
                            onClick={() => handleCurrentImage(item.url)} 
                        />
                    ))
                )}
            </div>

            {/* Main Image Display */}
            <div className="flex justify-center w-fit h-auto">
                {isMainImageLoading ? (
                    // Simulating Skeleton loading effect for main image
                    <Skeleton 
                        variant="rectangular" 
                        width={500} // Adjust width to match final image width
                        height={600} // Match the height to the final image height
                        animation="wave" 
                        sx={{ borderRadius: 1 }} // Optional: add border radius to match the image
                    />
                ) : imgUrlIsValid ? (
                    <img 
                        className="w-auto h-[600px] p-1 object-contain" 
                        src={currentImage} 
                        alt={productName} 
                    />
                ) : (
                    <Typography variant="body1" className="text-red-500">Image not available</Typography>
                )}
            </div>
        </Box>
    );
};

export default ProductGallery;