import React, { useState } from 'react';
import { Box, Typography, Autocomplete, TextField, Chip } from '@mui/material';
import LensBlurOutlinedIcon from '@mui/icons-material/LensBlurOutlined';

const CategorySelector = ({ allCategories, newProduct, setNewProduct }) => {
  const [categoryInput, setCategoryInput] = useState('');

  const handleCategoryChange = (event, newValue) => {
    setNewProduct((prevState) => ({ ...prevState, categories: newValue }));
  };

  const handleAddCategory = () => {
    const trimmedCategory = categoryInput.trim();
    if (trimmedCategory && !newProduct.categories.includes(trimmedCategory)) {
      setNewProduct((prevState) => ({
        ...prevState,
        categories: [...prevState.categories, trimmedCategory],
      }));
    }
    setCategoryInput('');
  };

  const handleRemoveCategory = (category) => {
    setNewProduct((prevState) => ({
      ...prevState,
      categories: prevState.categories.filter((cat) => cat !== category),
    }));
  };

  return (
    <Box className="mt-4">
      <Typography variant="h6" className="flex items-center gap-2"><LensBlurOutlinedIcon/>Categorías</Typography>
      <Autocomplete
        multiple
        freeSolo
        options={allCategories
          .map((cat) => ({ title: cat, firstLetter: cat[0].toUpperCase() }))
          .sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
        groupBy={(option) => option.firstLetter}
        getOptionLabel={(option) => (typeof option === "string" ? option : option.title)}
        value={newProduct.categories}
        onChange={(event, newValue) => {
          const uniqueCategories = [...new Set(newValue.map(cat => (typeof cat === "string" ? cat.trim() : cat.title)))];
          setNewProduct((prevState) => ({ ...prevState, categories: uniqueCategories }));
        }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              key={index}
              label={typeof option === "string" ? option : option.title}
              {...getTagProps({ index })}
              color="primary"
              onDelete={() => handleRemoveCategory(option)}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Seleccionar o agregar categoría"
            value={categoryInput}
            variant="standard"
            onChange={(e) => setCategoryInput(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleAddCategory();
              }
            }}
          />
        )} 
        filterSelectedOptions
      />
    </Box>
  );
};

export default CategorySelector;