import {Box,Button, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'



const ImageHomeCard = ({text='dressCustom', imgUrl }) => {

  let navigate = useNavigate(); 
  const routeChange = () =>{ 
    let path = "/"+text; 
    navigate(path);
  }


  return (
  <Box
    className={`flex justify-center items-center flex-col gap-5 w-full  bg-cover bg-center h-[450px]
                ${imgUrl ? '' : 'bg-blue-500'}`}
    style={imgUrl ? { backgroundImage: `url(${imgUrl})`, backgroundSize: "cover", backgroundPosition: "center" } : {}}
  >
  <Typography 
    variant='h5' 
    component='h5' 
    className='font-[Ephesis] text-white shadow-md text-center'
  >
    {text}
  </Typography>
  <button className='button-23-reverse' variant="outlined" onClick={routeChange}>
    Shop Now
  </button>
</Box>



  )
}

export default ImageHomeCard