import React, { useState, useEffect } from 'react';
import {
  Card,
  CardMedia,
  Box,
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  TextField,
  FormControlLabel,
  Skeleton,
  DialogTitle,
  Switch,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import {
  Title,
  Category,
  CalendarToday,
  CheckCircle,
  Cancel,
} from '@mui/icons-material';
import CheckboxTile from '../../Cards/CheckboxTile';
import { uploadImage } from '../../../../services/gcpServices';
import useImageValidation from '../../../../utils/ImageValidator';



const FashionProductDetailsModal = ({ open, onClose, onSave, product }) => {
  const [editMode, setEditMode] = useState(false);
  const [updatedProduct, setUpdatedProduct] = useState(product);
  const [selectedCategories, setSelectedCategories] = useState(product.categories || []);
  const [imageFile, setImageFile] = useState(null);
  const { imgUrlIsValid, isLoading } = useImageValidation(updatedProduct.image);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedProduct((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleCategoryChange = (category, checked) => {
    const newCategories = checked
      ? [...selectedCategories, category]
      : selectedCategories.filter((c) => c !== category);
    setSelectedCategories(newCategories);
    setUpdatedProduct((prevState) => ({ ...prevState, categories: newCategories }));
  };

  const handleSave = async () => {
    try {
      let imgUrl = updatedProduct.imgUrl;
      if (imageFile) {
        imgUrl = await uploadImage(imageFile, 'Products');
      }
      const updatedProductData = { ...updatedProduct, imgUrl };
      await onSave(updatedProductData);
      setEditMode(false);
    } catch (error) {
      console.error('Failed to save product:', error);
    }
  };

  const handleEditToggle = () => setEditMode(!editMode);

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImageFile(e.target.files[0]);
      setUpdatedProduct((prevState) => ({
        ...prevState,
        imgUrl: URL.createObjectURL(e.target.files[0]),
      }));
    }
  };

  useEffect(() => {
    if (product) {
      setUpdatedProduct(product);
    }
  }, [product]);



  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {editMode ? 'Editar Producto' : 'Detalles del Producto'}
        <IconButton onClick={onClose} style={{ position: 'absolute', right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Card>
          {updatedProduct.image ? (
            <CardMedia
              component="img"
              image={updatedProduct.image}
              title={updatedProduct.productTitle}
              sx={{ maxHeight: 400, objectFit: 'contain', widows:'fit-content'}}
            />
          ) : (
            <Skeleton variant="rectangular" width="100%" height={200} />
          )}
          {editMode && (
            <Box position="relative">
              <input
                accept="image/*"
                type="file"
                onChange={handleImageChange}
                style={{ display: 'none' }}
                id="upload-image"
              />
              <label htmlFor="upload-image">
                <IconButton component="span">
                  <EditIcon />
                </IconButton>
              </label>
            </Box>
          )}
        </Card>

        <Card>
          {isLoading ? (
            <Skeleton
              variant="rectangular"
              width="60%"
              height={200}
              animation="wave"
              sx={{ borderRadius: '4px' }}
            />
          ) : imgUrlIsValid ? (
            <CardMedia
              component="img"
              sx={{
                width: 'auto', // Set width to 70%
                height: 250, // Allow height to adjust automatically
                display: 'block',
                margin: 'auto',
                objectFit: 'cover',
              }}
              image={updatedProduct.image}
              title={updatedProduct.productName}
          />
          ) : (
            <div className="flex justify-center items-center">
              <img
                src={'../assets/icons/DashboardAdmin/folder.png'}
                alt="Fallback Icon"
                style={{
                  width: 'auto',
                  height: 'auto',
                  maxHeight: '200px',
                  padding: '1em',
                  objectFit: 'contain',
                }}
              />
            </div>
          )}
        </Card>

        <Box mt={2}>
          {editMode ? (
            <>
              <TextField
                label="SKU"
                name="sku"
                fullWidth
                value={updatedProduct.sku}
                onChange={handleInputChange}
                sx={{ mb: 2 }}
              />
              <TextField
                label="Título del Producto"
                name="productTitle"
                fullWidth
                value={updatedProduct.productTitle}
                onChange={handleInputChange}
                sx={{ mb: 2 }}
              />
              <TextField
                label="Descripción"
                name="description"
                fullWidth
                multiline
                rows={4}
                value={updatedProduct.description}
                onChange={handleInputChange}
                sx={{ mb: 2 }}
              />
              <TextField
                label="Precio"
                name="price"
                type="number"
                fullWidth
                value={updatedProduct.price}
                onChange={handleInputChange}
                sx={{ mb: 2 }}
              />
              <TextField
                label="Tamaño"
                name="size"
                fullWidth
                value={updatedProduct.size}
                onChange={handleInputChange}
                sx={{ mb: 2 }}
              />
              <TextField
                label="Color"
                name="color"
                fullWidth
                value={updatedProduct.color}
                onChange={handleInputChange}
                sx={{ mb: 2 }}
              />
              <Box mt={2}>
                <Typography variant="subtitle1">Categorías</Typography>
                <Box className="flex flex-wrap gap-2">
                  {['Camisas', 'Pantalones', 'Zapatos'].map((category) => (
                    <CheckboxTile
                      key={category}
                      label={category}
                      checked={selectedCategories.includes(category)}
                      onChange={(checked) => handleCategoryChange(category, checked)}
                    />
                  ))}
                </Box>
              </Box>
            </>
          ) : (
            <Box>
              <Typography variant="h6">{updatedProduct.productTitle}</Typography>
              <Typography variant="body2">SKU: {updatedProduct.sku}</Typography>
              <Typography variant="body1">Descripción: {updatedProduct.description}</Typography>
              <Typography variant="body1">Precio: ${updatedProduct.price}</Typography>
              <Typography variant="body1">Tamaño: {updatedProduct.size}</Typography>
              <Typography variant="body1">Color: {updatedProduct.color}</Typography>
              <Typography variant="body2">Categorías: {selectedCategories.join(', ')}</Typography>
            </Box>
          )}
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
          <Button onClick={onClose} variant="contained" color="error">
            Cerrar
          </Button>
          {editMode ? (
            <Button onClick={handleSave} variant="contained" color="primary">
              Guardar
            </Button>
          ) : (
            <Button onClick={handleEditToggle} variant="contained" color="primary">
              Editar
            </Button>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default FashionProductDetailsModal;
