import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { Box, Divider, Skeleton } from "@mui/material";

import CardProduct from "@/components/Cards/CardProductPrev";
import Accordion from "@/components/Cart/Accordion";
import Newsletter from "@/components/Newsletter";
import { addProduct } from "@/redux/cartRedux";
import { getProduct, getProductByCollection, getProductGallery } from "../services/products";
import SizeChartDialog from "../components/Dialog/SizeChartDialog";
import ProductGallery from "../components/ImageCarousel/ProductGallery";
import { handleWhatsAppRedirect } from "../utils/WhatsAppUtils";

const Container = styled.div``;
const ContentContainer = styled.div``;
const DetailsContainer = styled.div`
  position: sticky;
  top: 20%;
  left: 53%;
`;
const ContainerPreviewProducts = styled.section``;

// ✅ Refactored: Size Selector Component
const SizeSelector = ({ sizeMap, selectedSize, setSize }) => {
  if (!sizeMap || typeof sizeMap !== "object") return null;

  // ✅ Fixed size order
  const sizeOrder = ["XS", "S", "M", "L", "XL", "XXL"];

  // ✅ Convert size map to array & ensure correct order
  const availableSizes = sizeOrder.map(size => ({
    size,
    isAvailable: sizeMap[size.toLowerCase()] > 0, // Check if size has stock
  }));

  return (
    <div className="flex space-x-3">
      {availableSizes.map(({ size, isAvailable }) => (
        <label
          key={size}
          className={`flex items-center justify-center font-[Cormorant Garamond] font-light w-10 h-10 border rounded-none cursor-pointer hover:bg-gray-200
            ${!isAvailable ? "line-through text-gray-400" : "border-gray-400"} ${
            selectedSize === size ? "border-2 border-black" : ""
          }`}
          onClick={() => isAvailable && setSize(size)} // Only allow clicking if in stock
        >
          <input type="radio" name="size" value={size} className="hidden" />
          <span className="text-sm font-[Cormorant Garamond]">{size}</span>
        </label>
      ))}
    </div>
  );
};
const ProductDetail = () => {
  const [product, setProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [gallery, setGallery] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [collection, setCollection] = useState([]); 

  const location = useLocation();
  const id = location.pathname.split("/")[3];
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when route changes
  }, [id]);

  useEffect(() => {
    const fetchProduct = async () => {
      setLoading(true);
      setProduct(null);
      setGallery([]);

      try {
        const response = await getProduct(id);
        const data = response.data;
        setProduct(data);
        setSelectedColor(data.color?.[0] || "");
        
        // ✅ Set the first available size as default
        const firstAvailableSize = Object.entries(data.size || {}).find(([, stock]) => stock > 0);
        setSelectedSize(firstAvailableSize ? firstAvailableSize[0].toUpperCase() : "");

        fetchGallery(data.gallery);
        if (data?.collection) {
          fetchProductByCollection(data.collection);
        }
      } catch (err) {
        console.error("Error fetching product:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id]);

  const fetchGallery = async (folder) => {
    if (!folder) return;
    try {
      const res = await getProductGallery(folder);
      setGallery(res.data.files);
    } catch (error) {
      console.error("Error loading gallery:", error);
    }
  };

  const fetchProductByCollection = async (collectionName) => {
    if (!collectionName) return;
    try {
      const res = await getProductByCollection(collectionName);
      setCollection(res.data);
    } catch (error) {
      console.error("Error fetching collection products:", error);
    }
  };

  const handleAddProduct = () => {
    if (!product) return;
    dispatch(addProduct({ ...product, quantity, color: selectedColor, size: selectedSize }));
  };

  return (
    <>
      <Container className="flex flex-wrap bg-yellow-nj">
        <Box className="w-full md:w-1/2">
          {product && (
            <ProductGallery
              productImages={gallery}
              productName={product?.title}
              mainProductImage={product?.image}
            />
          )}
        </Box>

        <ContentContainer className="flex flex-col gap-5 relative w-full md:w-1/2 p-5">
          <DetailsContainer className="bg-yellow-nj w-11/12 flex flex-col gap-2">
            {loading ? (
              <Skeleton variant="text" width="80%" height={30} />
            ) : (
              <div className="flex flex-wrap justify-between items-end">
                <h3 className="text-4xl font-bold">{product?.title}</h3>
                <h3 className="text-lg font-bold font-Sans">${product?.price}</h3>
              </div>
            )}

            {/* Colors Section */}
            <div className="space-y-4">
              <h2 className="text-lg font-semibold">Colores</h2>
              <div className="flex space-x-4">
                {product?.color?.map((color, index) => (
                  <button
                    key={index}
                    className={`w-8 h-8 rounded-full border transition-transform duration-200 hover:scale-110 ${
                      selectedColor === color
                        ? "border-1 border-black ring-1 ring-offset-2 ring-black"
                        : "border-gray-400"
                    }`}
                    style={{ backgroundColor: color }}
                    title={color}
                    aria-label={`Color option: ${color}`}
                    onClick={() => setSelectedColor(color)}
                  />
                ))}
              </div>
            </div>

            {/* Sizes Section */}
            <div className="flex flex-col space-y-2">
              <h2 className="text-lg font-semibold">Tallas</h2>
              <SizeSelector sizeMap={product?.size} selectedSize={selectedSize} setSize={setSelectedSize} />
            </div>

            <div style={{ padding: "20px" }}>
              <SizeChartDialog />
            </div>

            <div className="flex justify-center w-full items-center my-10">
              <button className="button-23" onClick={()=> handleWhatsAppRedirect(product)} disabled={loading}>
                Contactar
              </button>
            </div>

            <Accordion />
          </DetailsContainer>
        </ContentContainer>
      </Container>

      <Newsletter />
    </>
  );
};

export default ProductDetail;