// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { A11y, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getAllProductList } from '../../services/products';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';


const CarouselBox = styled(Swiper)`
  width: 100%;
  height: 600px;
  display: inline-grid;
  position: relative;
  overflow: hidden;
  

  // Remove any margin/padding issues
  & .swiper-slide {
    margin: 0 0px ; // Remove margin around slides if any
    padding: 0; // Ensure no padding inside slides
  }

  // Adjust arrow button positioning
  & .swiper-button-next,
  & .swiper-button-prev {
    color: black;
    position: absolute;
    top: 50%;  // Center vertically
    transform: translateY(-50%);
    z-index: 10; // Make sure buttons are on top of the content
  }

  & .swiper-button-next {
    right: -50px; // Move the "next" button outside to the right
  }

  & .swiper-button-prev {
    left: -20px; // Move the "prev" button outside to the left
  }

  & .swiper-button-next:hover,
  & .swiper-button-prev:hover {
    color:rgb(105, 105, 105); // Hover color for better accessibility
  }

  & .swiper-pagination {
    color: black;
    position: absolute;
    bottom: 20px;
    z-index: 10;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.7);
  }

  & .swiper-pagination-bullet {
    background-color: black;
  }

  & .swiper-pagination-bullet-active {
    background-color: #ff5722; // Active pagination color
  }

  @media (max-width: 600px) {
    height: fit-content;
    padding: 0 10px;
    
    & .arrow {
      display :none;
    }

  }

  @media (max-width: 900px) {
    height: 500px;
  }
`;


const Slider = styled.div`
  position: relative;
  height: 650px;
  width: 100vw;
  overflow: hidden;
  top: 0;

  @media (max-width: 600px) {
    height: 100%;
  }
`;


const CustomArrow = ({ direction, onClick }) => (
  <button
    className={`absolute top-1/2 transform -translate-y-1/2 z-10 bg-transparent border-none text-black text-4xl cursor-pointer 
      ${direction === 'left' ? 'left-[-50px]' : 'right-[-50px]'}`}
    onClick={onClick}
  >
    {direction === 'left' ? '←' : '→'}
  </button>
);

const ProductsSlider = () => {
  const [productList,setProductList] = useState([]);
  const swiperRef = useRef(null);

  useEffect(()=>{
    getProductList();
  },[])

  const getProductList = async () => {
    try {
      const response = await getAllProductList();

      if (!response || response.length === 0) {
        console.warn("No products found.");
        return;
      }
      setProductList(response)

      console.log("Product List:", response);
    } catch (error) {

      console.error("Error fetching product list:", error.message);
    }
  };
  

  return(
  <Box className='flex flex-row w-full'>
      <button className="arrow-left arrow px-10 hidden sm:block"><ArrowBackIosIcon/></button>
   
     <CarouselBox
      modules={[Navigation, Pagination, A11y]}
      loop={true}
      navigation={{ nextEl: ".arrow-right", prevEl: ".arrow-left" }}
      slidesPerView={1}
      spaceBetween={10}
      initialSlide={5}
      centeredSlides={true}
      ref={swiperRef}
      breakpoints={{
        320: {
          slidesPerView: 1,
          spaceBetween: 0,
          centeredSlides:true,
        },
        480: {
          slidesPerView: 1,
          spaceBetween: 10,
          centeredSlides:true,
        },
        660: {
          slidesPerView: 1,
          spaceBetween: 0,
          centeredSlides:true,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 0,
        },
        960: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        1280: {
          slidesPerView: 4,
          spaceBetween: 40,
        },
        1440: {
          slidesPerView: 4,
          spaceBetween: 50,
        },
      }}
      
    >
     {
        productList.length > 0 ? (
          productList.map((item, index) => (
           
            <SwiperSlide key={`slide-${index}`}>
               <Link to={`/ProductList/${item.categories[0]}/${item.id}`}>
              <Slider id={`slide-${index}`} className="flex flex-wrap justify-center items-center cursor-pointer">
                {item.image ? (
                
                    <img 
                      src={item.image} 
                      alt={`Product ${index + 1}`} 
                      className="object-contain h-[350px] w-auto max-w-full"
                      width="auto" 
                      loading="lazy"
                    />
                  
                ) : (
                  <div className="text-gray-500 text-sm">Image not available</div>
          )}
        </Slider>
        </Link>
      </SwiperSlide>
    
    ))
  ) : (
    <div className="text-center text-gray-500 py-5">No products available</div>
  )
}

    </CarouselBox>
      <button className="arrow-right arrow px-10 hidden sm:block"><ArrowForwardIosIcon/></button>
  </Box>)
};

export default ProductsSlider;