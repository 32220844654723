import React from 'react';
import { Box, Typography, TextField } from '@mui/material';

const SizeSelectionBox = ({ sizeChartData, newProduct, handleSizeChange }) => {
  return (
    <Box className="grid grid-cols-2 md:grid-cols-8 gap-4 mt-4">
      {sizeChartData.map(({ size }) => (
        <Box 
          key={size} 
          className="p-4 border rounded-lg shadow-md flex flex-col items-center bg-white"
        >
          <Typography variant="body1" className="font-semibold">{size}</Typography>
          <TextField
            type="number"
            size="small"
            value={newProduct.sizes[size]}
            onChange={(e) => handleSizeChange(size, e.target.value)}
            className="mt-2 w-20"
            inputProps={{ min: 0 }}
          />
        </Box>
      ))}
    </Box>
  );
};

export default SizeSelectionBox;
