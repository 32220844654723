import React, { useState } from 'react';
import { Box, Typography, Autocomplete, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

const defaultCollections = ["Primavera", "Verano", "Otoño", "Invierno", "Edición Especial"];

const CollectionSelector = ({ allCollections = defaultCollections, newProduct, setNewProduct }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [newCollectionName, setNewCollectionName] = useState('');

  const handleCollectionChange = (event, newValue) => {
    if (typeof newValue === "string" && !allCollections.includes(newValue)) {
      setNewCollectionName(newValue);
      setOpenDialog(true);
    } else {
      setNewProduct((prevState) => ({ ...prevState, collection: newValue }));
    }
  };

  const handleAddCollection = () => {
    const trimmedCollection = newCollectionName.trim();
    if (trimmedCollection && !allCollections.includes(trimmedCollection)) {
      setNewProduct((prevState) => ({ ...prevState, collection: trimmedCollection }));
    }
    setNewCollectionName('');
    setOpenDialog(false);
  };

  return (
    <Box className="mt-4">
      <Typography variant="h6">Colección</Typography>
      <Autocomplete
        freeSolo
        options={allCollections.sort((a, b) => a.localeCompare(b))}
        value={newProduct.collection || ''}
        onChange={(event, newValue) => handleCollectionChange(event, newValue)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Seleccionar o agregar colección"
            variant="standard"
          />
        )}
      />

      {/* Dialog for adding a new collection */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Agregar Nueva Colección</DialogTitle>
        <DialogContent>
          <TextField
            label="Nombre de la colección"
            fullWidth
            variant="standard"
            value={newCollectionName}
            onChange={(e) => setNewCollectionName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="secondary">Cancelar</Button>
          <Button onClick={handleAddCollection} color="primary" variant="contained">Agregar</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CollectionSelector;
